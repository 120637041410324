<template>
  <section>
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          :statistic="`${dataPeserta}`"
          statistic-title="Peserta"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ServerIcon"
          color="success"
          :statistic="`${dataDosen}`"
          statistic-title="Dosen"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ActivityIcon"
          color="danger"
          :statistic="`${dataArtikel}`"
          statistic-title="Artikel"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="AlertOctagonIcon"
          color="warning"
          :statistic="`${dataProduct}`"
          statistic-title="Soal"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
export default {
  components: {
    BCard,
    BImg,
    BCardText,
    BBadge,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BRow,
    BCol,
    BMedia,StatisticCardHorizontal,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      nama: localStorage.getItem("Unama"),
      email: localStorage.getItem("Uemail"),
      level: localStorage.getItem("Ulevel"),
      kampus: localStorage.getItem("Ukampus"),
      nameMonth: "",
      dataPeserta: [],
      dataDosen: [],
      dataArtikel: [],
      dataProduct: [],
      // VUE_APP_SERVICE_URL: process.env.VUE_APP_SERVICE_URL
    };
  },
  mounted() {
    this.checkAuth();
    this.getMonthName();
    this.getCountPeserta();
    this.getCountDosen();
    this.getCountArtikel();
    this.getCountProduct();
  },
  //   created() {
  //   this.getMonthName();
  // },
  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      var levelCheck = localStorage.getItem("Ulevel");
      if (sessionCheck == null || sessionCheck == "") {
                    this.$router.push({ name: "login" });
      }
      else if(levelCheck != 2){
            this.$router.push({ name: "dashboard" });
      }
    },
    getMonthName() {
      // const date = new Date(); // 2009-11-10
      // const month = date.toLocaleString("default", { month: "long" });
      // this.nameMonth = month;

      const arrbulan = ["Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"];
      const arrhari = ["Minggu","Senin","Selasa","Rabu","Kamis","Jum'at","Sabtu"];
      var date = new Date();
      var millisecond = date.getMilliseconds();
      var detik = date.getSeconds();
      var menit = date.getMinutes();
      var jam = date.getHours();
      var hari = date.getDay();
      var tanggal = date.getDate();
      var bulan = date.getMonth();
      var tahun = date.getFullYear();
      var time = arrhari[hari] +", "+ tanggal +" "+ arrbulan[bulan] +" "+ tahun +", "+ jam +" : "+ menit + " : " + detik;
      this.nameMonth = time;
    },
    async getCountPeserta() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "users/count")
        .then((res) => {
          this.dataPeserta = res.data.data;
        });
    },
    async getCountDosen() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "teachers/count")
        .then((res) => {
          this.dataDosen = res.data.data;
        });
    },
    async getCountArtikel() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "blogs/count")
        .then((res) => {
          this.dataArtikel = res.data.data;
        });
    },
    async getCountProduct() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "products/count")
        .then((res) => {
          this.dataProduct = res.data.data;
        });
    },
  },
};
</script>
